var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        attrs: {
          striped: "",
          hover: "",
          small: "",
          fields: _vm.colunas,
          items: _vm.items,
          "sort-by": _vm.sortColumn,
          "sort-desc": _vm.sortDesc,
          busy: _vm.loading,
          "no-local-sorting": !_vm.porPagina && !_vm.forceScriptOrdenacao,
          "per-page": _vm.porPagina ? _vm.porPagina : 0,
          "current-page": _vm.paginaAtual,
          "show-empty": "",
          "empty-text": "Sem registro a exibir",
          "empty-filtered-text": "Nenhum registro encontrado"
        },
        on: {
          "update:sortBy": function($event) {
            _vm.sortColumn = $event
          },
          "update:sort-by": function($event) {
            _vm.sortColumn = $event
          },
          "update:sortDesc": function($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function($event) {
            _vm.sortDesc = $event
          },
          "sort-changed": _vm.forceRefresh
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.colunas, function(coluna, idx) {
              return {
                key: _vm.vSlotCellName(coluna.key),
                fn: function(data) {
                  return [
                    _vm._t(
                      "renderCell(" + coluna.key + ")",
                      [
                        _c("span", { key: "col_" + idx }, [
                          coluna.render
                            ? _c("span", [
                                _vm._v(" " + _vm._s(coluna.render(data)) + " ")
                              ])
                            : _c("span", [
                                _vm._v(" " + _vm._s(data.value) + " ")
                              ])
                        ])
                      ],
                      null,
                      data
                    )
                  ]
                }
              }
            }),
            {
              key: "table-busy",
              fn: function() {
                return [_c("ui-loading")]
              },
              proxy: true
            },
            {
              key: "cell(id)",
              fn: function(data) {
                return [_vm._v(" " + _vm._s(data.item.id) + " ")]
              }
            },
            {
              key: "cell(status)",
              fn: function(data) {
                return [
                  _c("UiSwitch", {
                    attrs: {
                      readonly: true,
                      labelTrue: data.field.labelTrue
                        ? data.field.labelTrue
                        : "Sim",
                      labelFalse: data.field.labelFalse
                        ? data.field.labelFalse
                        : "Não"
                    },
                    model: {
                      value: data.item.status,
                      callback: function($$v) {
                        _vm.$set(data.item, "status", $$v)
                      },
                      expression: "data.item.status"
                    }
                  })
                ]
              }
            },
            {
              key: "cell(ativo)",
              fn: function(data) {
                return [
                  _c("UiSwitch", {
                    attrs: {
                      readonly: true,
                      labelTrue: data.field.labelTrue
                        ? data.field.labelTrue
                        : "Sim",
                      labelFalse: data.field.labelFalse
                        ? data.field.labelFalse
                        : "Não"
                    },
                    model: {
                      value: data.item.ativo,
                      callback: function($$v) {
                        _vm.$set(data.item, "ativo", $$v)
                      },
                      expression: "data.item.ativo"
                    }
                  })
                ]
              }
            },
            {
              key: "cell(progresso)",
              fn: function(data) {
                return [
                  _c("ui-progress", {
                    staticClass: "mt-2",
                    attrs: { value: data.item.progresso }
                  })
                ]
              }
            },
            {
              key: "cell(action)",
              fn: function(data) {
                return [
                  _c(
                    "b-button-group",
                    { attrs: { size: "sm" } },
                    [_vm._t("actions", null, { data: data.item })],
                    2
                  )
                ]
              }
            },
            {
              key: "head(action)",
              fn: function(data) {
                return [
                  _c("div", { staticClass: "d-flex flex-nowrap w-100" }, [
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(data.label))
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: { "flex-grow": "1", "text-align": "right" }
                      },
                      [
                        _c(
                          "b-button-group",
                          { attrs: { size: "sm" } },
                          [_vm._t("hactions", null, { data: data })],
                          2
                        )
                      ],
                      1
                    )
                  ])
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }